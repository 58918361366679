import qs from "qs";

import { IFilter, ISortBy } from "./types/common";
import { IGetCompaniesQuery } from "./types/companies";

export const withPagination = (pageIndex: number, pageSize: number) => {
    return {
        page: pageIndex,
        limit: pageSize,
    };
};

const withGlobalFilter = (global: string) => {
    return {
        global,
    };
};

const withFilters = (filters?: IFilter[]) => {
    return {
        filters,
    };
};

const withStates = (state: string) => {
    return {
        state,
    };
};

const withSortBy = (sortBy?: ISortBy[]) => {
    return {
        ...(sortBy?.[0] && {
            sort: sortBy[0].id,
            desc: sortBy[0].desc ? "desc" : "asc",
        }),
    };
};

export const getCompaniesQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    filters = [],
    sortBy = [],
    globalFilter = "",
    globalState = "",
}: IGetCompaniesQuery) => {
    return qs.stringify(
        {
            ...withPagination(pageIndex, pageSize),
            ...withFilters(filters),
            ...withSortBy(sortBy),
            ...withGlobalFilter(globalFilter),
            ...withStates(globalState),
        },
        { encode: true }
    );
};
