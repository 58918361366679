import { configureStore } from "@reduxjs/toolkit";

import { authApi } from "./auth/api";
import { clientApi } from "./client/api";
import { companyApi } from "./company/api";
import { questionnairesApi } from "./questionnaire/api";
import { reviewApi } from "./review/api";
import rootReducer from "./root-reducer";
import { userApi } from "./user/api";

const middleWares: any = [
    authApi.middleware,
    userApi.middleware,
    questionnairesApi.middleware,
    reviewApi.middleware,
    companyApi.middleware,
    clientApi.middleware,
];

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(...middleWares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
