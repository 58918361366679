import { FC } from "react";

import { Navigate } from "react-router-dom";

import { getTokenFromCookies } from "../shared/cookies-actions";

interface PrivateRouteProps {
    component: FC<any>;
    props?: any;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, ...props }) => {
    const token = getTokenFromCookies();

    return token ? (
        <>
            <Component {...props} />
        </>
    ) : (
        <Navigate to="/sign-in" />
    );
};

export default PrivateRoute;
