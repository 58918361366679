import { createApi } from "@reduxjs/toolkit/query/react";

import { IGetMainQuestionnaireQuery, IGetMainQuestionnaireResponse } from "../../shared/types/questionnaires";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "questionnaires";

export const questionnairesApi = createApi({
    reducerPath: "questionnairesApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Questionnaire"],
    endpoints: builder => ({
        getMainQuestionnaire: builder.query<IGetMainQuestionnaireResponse, IGetMainQuestionnaireQuery>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/main`,
                method: "GET",
            }),
            providesTags: ["Questionnaire"],
        }),
    }),
});

export const { useGetMainQuestionnaireQuery } = questionnairesApi;
