import { createApi } from "@reduxjs/toolkit/query/react";

import { ISignUpPayload, ISignUpResponse, ISignInResponse, ISignInPayload } from "../../shared/interfaces/auth";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "clients";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Auth"],
    endpoints: builder => ({
        signUp: builder.mutation<ISignUpResponse, ISignUpPayload>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}`,
                method: "POST",
                body: data,
            }),
        }),
        signIn: builder.mutation<ISignInResponse, ISignInPayload>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/sign-in`,
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const { useSignUpMutation, useSignInMutation } = authApi;
