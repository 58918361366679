import "./styles/scss/index.scss";

import { Suspense, useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import { ROUTES } from "./pages";
import PrivateRoute from "./pages/PrivateRoute";
import { setTokenToCookies } from "./shared/cookies-actions";

export const App = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramToken = queryParams.get("data");
    useEffect(() => {
        if (paramToken) {
            setTokenToCookies(paramToken);
        }
    }, [paramToken]);
    return (
        <Routes>
            {ROUTES.map(el => {
                const RouteComponent = el.isPrivate ? (
                    <PrivateRoute component={el.component} />
                ) : (
                    <el.component />
                );
                return (
                    <Route
                        key={el.id}
                        path={el.path}
                        element={
                            <Suspense fallback={null}>
                                {RouteComponent}
                            </Suspense>
                        }
                    />
                );
            })}
        </Routes>
    );
};
