import { createApi } from "@reduxjs/toolkit/query/react";

import { IReviewRequest } from "../../shared/interfaces/review";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "reviews";

export const reviewApi = createApi({
    reducerPath: "reviewApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Review"],
    endpoints: builder => ({
        addReview: builder.mutation<any, IReviewRequest>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}`,
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const { useAddReviewMutation } = reviewApi;
