import { createApi } from "@reduxjs/toolkit/query/react";

import {
    IPurchaseRequest,
    IPurchaseResponse,
    ISendEmailRequest,
    ISendEmailResponse,
    IResetPasswordEmailRequest,
    IResetPasswordRequest,
} from "../../shared/interfaces/user";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "clients";

export const clientApi = createApi({
    reducerPath: "clientApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["User"],
    endpoints: builder => ({
        purchaseCompany: builder.mutation<IPurchaseResponse, IPurchaseRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/purchase`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["User"],
        }),
        sendEmail: builder.mutation<ISendEmailResponse, ISendEmailRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/send-email`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["User"],
        }),
        resetPasswordEmail: builder.mutation<ISendEmailResponse, IResetPasswordEmailRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/reset-password-email`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["User"],
        }),
        resetPassword: builder.mutation<ISendEmailResponse, IResetPasswordRequest>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/reset-password`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["User"],
        }),
    }),
});

export const {
    usePurchaseCompanyMutation,
    useSendEmailMutation,
    useResetPasswordMutation,
    useResetPasswordEmailMutation,
} = clientApi;
