import { createApi } from "@reduxjs/toolkit/query/react";

import { getCompaniesQueryString } from "../../shared/queries";
import { IGetCompaniesQuery, IGetCompaniesResponse, IGetCompanyByIdResponse } from "../../shared/types/companies";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "companies";

export const companyApi = createApi({
    reducerPath: "companyApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Companies"],
    endpoints: builder => ({
        getCompanies: builder.query<IGetCompaniesResponse, IGetCompaniesQuery>({
            query: query => ({
                url: `/${ENDPOINT_PREFIX}?${getCompaniesQueryString(query)}`,
            }),
            providesTags: ["Companies"],
        }),
        getCompanyById: builder.query<IGetCompanyByIdResponse, string>({
            query: id => ({ url: `/${ENDPOINT_PREFIX}/${id}` }),
        }),
    }),
});

export const { useGetCompaniesQuery, useGetCompanyByIdQuery } = companyApi;
