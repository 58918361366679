import { createApi } from "@reduxjs/toolkit/query/react";

import { IUserResponse, IUser, IUserInfo } from "../../shared/interfaces/user";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "clients";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["User"],
    endpoints: builder => ({
        getUser: builder.query<IUserResponse, undefined>({
            query: () => ({
                url: `/${ENDPOINT_PREFIX}/currentClient`,
                method: "GET",
            }),
        }),
        updateUserData: builder.mutation<IUser, { info: IUserInfo }>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/currentClient`,
                method: "PATCH",
                body: data,
            }),
            invalidatesTags: ["User"],
        }),
    }),
});

export const { useGetUserQuery, useUpdateUserDataMutation } = userApi;
