import { combineReducers } from "@reduxjs/toolkit";

import { authApi } from "./auth/api";
import { clientApi } from "./client/api";
import { companyApi } from "./company/api";
import { questionnairesApi } from "./questionnaire/api";
import { reviewApi } from "./review/api";
import { userApi } from "./user/api";

const rootReducer = combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [questionnairesApi.reducerPath]: questionnairesApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
