import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { message } from "antd";

import { getTokenFromCookies, removeTokenFromCookies } from "../../shared/cookies-actions";
import { IMessage } from "../../shared/types/common";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: headers => {
        const token = getTokenFromCookies();

        if (token !== null) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

export const baseQueryWithIntercept: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error?.data) {
        message.error((result.error.data as IMessage)?.message);
        if (result.error?.status === 401) {
            removeTokenFromCookies();
            window.location.href = "/sign-in";
            window.location.reload();
        }
    }

    return result;
};
