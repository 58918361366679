import { lazy, ComponentType, LazyExoticComponent } from "react";

export interface IROUTE_ITEM {
  id: number | string;
  path: string;
  component: LazyExoticComponent<ComponentType<any>>;
  isPrivate: boolean;
}

export const ROUTES: IROUTE_ITEM[] = [
    {
        id: 1,
        path: "/",
        component: lazy(() => import("./home")),
        isPrivate: false,
    },
    {
        id: 2,
        path: "/sign-up",
        component: lazy(() => import("./sign-up")),
        isPrivate: false,
    },
    {
        id: 3,
        path: "/sign-in",
        component: lazy(() => import("./sign-in")),
        isPrivate: false,
    },
    {
        id: 4,
        path: "/profile",
        component: lazy(() => import("./profile")),
        isPrivate: true,
    },
    {
        id: 5,
        path: "/company-list",
        component: lazy(() => import("./company-list")),
        isPrivate: true,
    },
    {
        id: 6,
        path: "/company-list/:id",
        component: lazy(() => import("./company-information")),
        isPrivate: true,
    },
    {
        id: 7,
        path: "/about-us",
        component: lazy(() => import("./about-us")),
        isPrivate: false,
    },
    {
        id: 8,
        path: "/questionnaire",
        component: lazy(() => import("./questionnaire")),
        isPrivate: true,
    },
    {
        id: 9,
        path: "/forgot-password",
        component: lazy(() => import("./forgot-password")),
        isPrivate: false,
    },
    {
        id: 9,
        path: "/forgot-password/:token",
        component: lazy(() => import("./reset-password")),
        isPrivate: false,
    },
    {
        id: 10,
        path: "/privacy-policy",
        component: lazy(() => import("./privacy-policy")),
        isPrivate: false,
    },
];
